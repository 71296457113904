import './App.css';
import logo from "./logo.png"

function App() {
  return (
    <div className="App">
        <h1>Coming soon</h1>
        <img
            className="logo"
            src={logo} alt="logo"
        />
    </div>
  );
}

export default App;
